


























import { useThemeUiState } from '~/diptyqueTheme/composable/useThemeUiState';
import { SfProperty } from '@storefront-ui/vue';
import { defineComponent, computed, useContext, onMounted } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import { lsGet, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';

export default defineComponent({
  name: 'VaimoStoreSwitcher',
  components: {
    SfProperty
  },
  props: {
    footer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const { config } = useConfig();
    const { app: { i18n: { locales } } = { i18n: { locales: [] } } } =
      useContext();
    const { toggleStoreSwitcherModal, toggleMobileStoreSwitcher } =
      useThemeUiState();

    const currentStoreLabel = computed(() => {
      const getStoreFromConfig = locales.find(
        (locale) => locale.code === config.value?.store_code
      );
      if (!getStoreFromConfig) return '';
      if (['en_us', 'fr_us'].includes(config.value?.store_code) && process.browser) {
        return `${lsGet('preferredAmericanStore')}, ${getStoreFromConfig.languageLabel}`;
      }
      return `${getStoreFromConfig.websiteName}, ${getStoreFromConfig.languageLabel}`;
    });

    const handleStoreSwitcherClick = () => {
      return props.footer
        ? toggleStoreSwitcherModal()
        : toggleMobileStoreSwitcher();
    };

    const setPreferredAmericanStore = () => {
      if (!lsGet('preferredAmericanStore')) {
        lsSet('preferredAmericanStore', 'United States');
      }
    }

    onMounted(() => {
      setPreferredAmericanStore();
    })

    return {
      handleStoreSwitcherClick,
      currentStoreLabel
    };
  }
});
